<script setup lang="ts">
    useHead({
        titleTemplate: '%s - The Political Room',
    })
</script>

<template>
    <div>
        <slot />
    </div>
</template>